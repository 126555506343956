<template>
    <div class="detail">
        <img :src="detailInfo.banner" alt />
        <div style="font-size:16px;" v-html="detailInfo.desc"></div>
        <div class="bottom">
            <div v-if="detailInfo.get_charge">
                <span class="new-price">¥{{detailInfo.get_charge.attr1_price}}</span>
                <span class="old-price">¥{{detailInfo.get_charge.attr2_price}}</span>
            </div>
            <div>
                <button class="btn a" @click="call">电话咨询</button>
                <button class="btn b" @click="submitOrder">立即报名</button>
            </div>
        </div>

        <van-action-sheet v-model="show" title="微服务高并发实战训练营">
            <div class="content">
                <p class="title">预约后我们将通过电话联系您</p>
                <input type="text" value="138****9898" />
                <p class="t">
                    <span>
                        <img src="../img/pro.png" alt />
                        <span class="t-text">无推销</span>
                    </span>
                    <span class="t-item">
                        <img src="../img/pro.png" alt />
                        <span class="t-text">不泄露用户手机号</span>
                    </span>
                </p>
                <button class="confirm" @click="submitOrder">确认预约</button>
            </div>
        </van-action-sheet>

        <van-action-sheet v-model="showPay" title="请选择支付方式">
            <div class="content-pay">
                <div class="flex-between flex-ai-center" @click="_wxPay">
                    <div >
                        <img src="../img/wx.png" class="img" alt />
                        <span class="pay-text">微信</span>
                    </div>
                    <div>
                        <img src="../img/select.png" class="icon" alt />
                    </div>
                </div>
                <!-- <div class="hr"></div>
                <div class="pay-item" @click="handlePay">
                    <div>
                        <img src="../img/zfb.png" class="img" alt />
                        <span class="pay-text">支付宝</span>
                    </div>
                    <div>
                        <img src="../img/select.png" class="icon" alt />
                    </div>
                </div> -->
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import { Dialog } from 'vant'
import { trainsDetail, saveOrder, payOrder } from '@/util/api'
import { makeCall } from '@/util'
import { storage } from '@/storage'

export default {
    data() {
        return {
            show: false,
            showPay: false,
            detailInfo: {},
            orderId: '',
            actions: [
                { name: "选项一" },
                { name: "选项二" },
                { name: "选项三", subname: "描述信息" }
            ]
        };
    },
    created () {
        const id = this.$route.query.id
        this._getDetailInfo(id)
    },
    methods: {
        _getDetailInfo (id) {
            trainsDetail({ id }).then(res => {
                this.detailInfo = res.data
            })
        },
        handlePay() {
            this.showPay = false;
            Dialog.alert({
                title: "是否已完成支付？",
                message: "已完成支付"
            }).then(() => {
                // on close
                this.$router.push("/success");
            });
        },
        submitOrder () {
            if (!storage.get('token')) {
                this.$router.push('/login?redirect=1')
                return
            }
            saveOrder({ train_id: this.detailInfo.id }).then(res => {
                this.showPay = true
                this.orderId = res.data.order_id
            })
        },
        _wxPay () {
            payOrder({ order_id: this.orderId }).then(res => {
                if (res.data.trade_type === 'MWEB') {
                    location.href = res.data.url
                } else if (res.data.trade_type === 'JSAPI') {
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    } else {
                        onBridgeReady(res.data)
                    }
                }
            })
        },
        _onBridgeReady(wxData) {
            WeixinJSBridge.invoke('getBrandWCPayRequest', wxData, function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                } else {
                    Toast.fail(res.err_msg)
                }
            });
        },
        call() {
            Dialog.confirm({
                title: "咨询电话",
                message: "4001001110"
            })
                .then(() => {
                    makeCall('4001001110')
                })
                .catch(() => {
                    // on cancel
                });
        },
        onSelect(item) {
            console.log("show...");
            this.show = false;
        }
    }
};
</script>

<style scoped>
.hr {
    width: 7rem;
    height: 1px;
    background: #d8d8d8;
    margin: 0.3rem auto;
}
.pay-text {
    vertical-align: 0.2rem;
    margin-left: 0.2rem;
}
.content-pay {
    font-size: 0.26rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.36rem;
    padding: 16px 16px;
}
.content-pay .img {
    width: 0.64rem;
    height: 0.64rem;
}
.content-pay .icon {
    width: 0.36rem;
    height: 0.36rem;
}
.t-item {
    margin-left: 0.3rem;
}
.t-text {
    vertical-align: 0.05rem;
}
.t {
    font-size: 0.24rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
    line-height: 0.36rem;
    display: flex;
    align-items: center;
}
.title {
    font-size: 0.24rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.36rem;
}
.content {
    padding: 16px 16px;
}
.content input {
    width: 6.52rem;
    height: 0.8rem;
    border-radius: 0.1rem;
    border: 1px solid #dddddd;
    font-size: 0.4rem;
}
.detail {
    padding-bottom: 2.5rem;
}
.detail img {
    width: 100%;
}
.bottom {
    width: 7.5rem;
    height: 1rem;
    /* background: #eee; */
    background: #ffffff;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.3rem;
    line-height: 1rem;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.07);
}
.btn {
    display: inline-block;
    width: 1.9rem;
    height: 0.6rem;
    border-radius: 0.3rem;
    border: 1px solid #ea742d;
    line-height: 0.6rem;
    background: #fff;
    border: none;
    margin-right: 0.2rem;
}
.a {
    font-family: PingFang-SC-Medium, PingFang-SC;
    color: #ea742d;
    border: 1px solid #ea742d;
}
.b {
    /* border: 1px solid #EA742D; */
    font-family: PingFang-SC-Medium, PingFang-SC;
    color: #ffffff;
    background: linear-gradient(180deg, #ffa936 0%, #eb732e 100%);
}
.new-price {
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ed1818;
    margin-left: 0.2rem;
}
.old-price {
    font-size: 0.22rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #bbbbbb;
    margin-left: 0.2rem;
    text-decoration: line-through;
}
.content img {
    width: 0.24rem;
    height: 0.28rem;
}
.confirm {
    width: 6.52rem;
    height: 0.8rem;
    background: linear-gradient(131deg, #38b5f8 0%, #1a7eef 100%);
    border-radius: 0.4rem;
    font-size: 0.26rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.36rem;
    border: none;
    display: block;
    margin: 0 auto;
}
</style>