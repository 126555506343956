
export function makeCall (phone) {
    const link = document.createElement('a')
    // 设置下载的文件名
    link.target = '_blank'
    link.style.display = 'none'
    // 设置下载路径
    link.href = `tel:${phone}`
    document.body.appendChild(link)
    // 触发点击
    link.click()
    // 移除节点
    document.body.removeChild(link)    
}
